<i18n>
ru:
  comment: Комментарий
ua:
  comment: Коментар
us:
  comment: Comment
</i18n>

<template>
  <div
    v-if="loaded"
    class="v-additional-form-address v-additional-form-address-custom"
  >
    <div class="v-address-form-full">
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: 'cart',
          Value: comment,
          Required: false,
          Validations: ['length'],
          MaxLength: 200
        }"
        :label="translate('comment.comment')"
        data-test-id="order-user-comment"
        v-model:text="comment"
      />
    </div>
  </div>
  <div v-else>
    <common-skeleton />
  </div>
</template>

<script setup lang="ts">
import { useCommon, vFormValidator, VInput } from '@arora/common'

const { translate, clean } = useI18nSanitized()
const { debounce } = useCommon()
const clientStore = useClientStore()

onMounted(async () => {
  await clientStore.initClientState()
})

const loaded = computed<boolean>(() => clientStore.ClientState?.data !== null)

const commentLocal = ref<string | null>(null)

const comment = computed<string>({
  get: () => commentLocal.value ?? clientStore.ClientState?.data?.StateOrderData?.Comment ?? '',
  set: (value) => {
    commentLocal.value = value
    debounce(() =>
      clientStore.updateOrderData({
        comment: clean(value ?? '')
      })
    )
  }
})
</script>

<style lang="scss">
.v-additional-form-address {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .v-address-form-full {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
  }
}
</style>
